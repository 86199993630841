<template>
  <div>
    <div class="flex-container">
      <div class="column-1">
        <div class="centered-div">
          <div class="custom-text-field">
            <v-text-field
              label="Rechercher"
              v-model="search"
              outlined
              clearable
              hide-details
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </div>
          <div class="custom-list" v-if="computedFiltreSearchSociete && computedFiltreSearchSociete.length >0">
            <v-checkbox
              label="Tous"
              class="checked-global"
              v-model="checkAll"
              @change="checkAllFiliales()"
              color="#704ad1"
              :value="true"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
            <div
              v-for="(item, index) in computedFiltreSearchSociete"
              :key="index"
            >
              <v-checkbox
                :label="item.text"
                class="checked-global"
                v-model="item.checked"
                @change="filiateToCheck(item)"
                color="#704ad1"
                :value="true"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="column-2">
        <div>
          <v-tabs v-model="activeTab" color="#704ad1" item-color="#704ad1">
            <v-tab color="#704ad1" item-color="#704ad1"
              >STATISTIQUE FACTURE</v-tab
            >
            <!-- <v-tab color="#704ad1" item-color="#704ad1"
              >STATISTIQUE OBLIGES</v-tab
            > -->
          </v-tabs>
          <v-tabs-items
            v-model="activeTab"
            color="#704ad1"
            item-color="#704ad1"
          >
            <v-tab-item>
              <statistics-facture
                :filiales="filiales"
                @checkList="handleCheckList"
              ></statistics-facture>
            </v-tab-item>
            <v-tab-item color="#704ad1" item-color="#704ad1">
              <div>
                <statistic-oblige :filiales="filiales"></statistic-oblige>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'static-global',
  data() {
    return {
      filiales: [],
      activeTab: 0,
      search: null,
      tab: null,
      checkAll: false
    };
  },
  computed: {
    ...mapGetters(['getFiliaeOfResponsable']),
    computedFilialeTh() {
      let vendeurNames = [];
      this.getFiliaeOfResponsable.map(item => {
        if (item.name !== null) {
          vendeurNames.push({
            value: item.id,
            text: item.name,
            type: item.type,
            checked: false
          });
        }
      });
      if (this.activeTab === 1) {
        vendeurNames.filter(item => item.type === 'master filiale');
      }
      return vendeurNames;
    },
    computedFiltreSearchSociete() {
      if (this.search === null) {
        return this.computedFilialeTh;
      } else {
        let societeToDisplay = this.computedFilialeTh.filter(item =>
          item?.text?.toLowerCase().includes(this.search.toLowerCase())
        );
        return societeToDisplay;
      }
    }
  },
  methods: {
    ...mapActions(['getFilialsOfConnectedResponsable']),
    checkAllFiliales() {
      this.computedFiltreSearchSociete.map(item => {
        item.checked = this.checkAll;
        this.filiateToCheck(item);
      });
    },
    filiateToCheck(item) {
      if (item.checked == true) {
        this.filiales.push(item);
      } else {
        this.filiales = this.filiales.filter(item => item.checked == true);
      }
    },
    handleCheckList(filiale) {
      this.computedFiltreSearchSociete.map(item => {
        if (item.value == filiale) {
          item.checked = false;
        }
      });
    }
  },
  components: {
    StatisticsFacture: () => import('../Statistiques/StatisticsFacture.vue'),
    StatisticOblige: () => import('../Statistiques/StatisticOblige.vue')
  },
  async mounted() {
    const response = await this.getFilialsOfConnectedResponsable({
      minimum: 'yes'
    });
    if (response.success == true) {
      if (this.computedFiltreSearchSociete.length > 0) {
        this.computedFiltreSearchSociete[0].checked = true;
        this.filiales.push(this.computedFiltreSearchSociete[0]);
      }
    }
  }
};
</script>

<style lang="scss">
.v-tab.v-tab--active {
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.v-tab {
  font-family: 'Montserrat';
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
</style>
<style lang="scss" scoped>
input[type='checkbox'] {
  position: relative;
  border: 2px solid #b7a4e7;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}

input[type='checkbox']:hover {
  opacity: 1;
}

input[type='checkbox']:checked {
  background-color: #b7a4e7;
  opacity: 1;
}

input[type='checkbox']:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.custom-list {
  margin-left: 2px;
  height: calc(100vh - 165px);
  overflow-y: auto;
}
.custom-list::-webkit-scrollbar {
  width: 10px;
}
.custom-list::-webkit-scrollbar-track {
  background-color: #fff;
}

.custom-list::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 4px;
}

.custom-list::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

.flex-container {
  display: flex;
  border-top: 1px solid #f6f6f6;
  .column-2 {
    flex: 3;
  }

  .column-1 {
    flex: 0.75;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 3px solid #f6f6f6;
    // box-shadow: 5px 5px 5px #F6F6F6;
  }
}

.centered-div {
  padding: 18px;
}

.custom-label-checkbox {
  left: 0px;
  right: auto;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: black;
  font-family: roboto;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.custom-text-field {
  padding-top: 15px;
  min-width: 250px !important;
  width: 250px !important ;
  position: relative;
  display: inline-block;
  label {
    position: absolute;
    top: 2px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
    font-family: 'Montserrat';
    z-index: 1;
    color: #c4c4c4;
  }
  input {
    width: 244px;
    height: 35px;
    padding: 8px 12px;
    border: 2px solid #c4c4c4;
    font-family: 'Montserrat';
    border-radius: 4px;
    outline: none;
  }
  input:focus {
    border-color: #c4c4c4;
  }
}
</style>
